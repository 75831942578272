/* html elements */
@import "_base.scss";

/**
 * The default font size is 16px x 81.3% = 13px
 * Use http://pxtoem.com to calculate proper font size and scale.
 *
 * The font size is set on the html element so we can dynamically
 * resize the font using body classes.
 */
html {
  font-size: /*81.3%*/ 100%;
}
body {
  font-family: $body-font;
  line-height: 1.6;
  font-size: 1.0625rem;
  color: $body-font-color;
}
h1 {
  @include h1;
}
h2 {
  @include h2;
  padding-bottom: 20px;
}
h3 {
  @include h3;
  margin-bottom: 6px;
  margin-top: 3px;
  clear: both;
  font-family: $body-font;
}
h4 {
  font-size: 1.077em;
}

h1,
h2,
h4 {
  font-family: $title-font;
}

h5,
h6 {
  font-size: 1em;
}
a img {
  border: none;
}
img {
  max-width: 100%; /* flexible images - can cause issues in table cells where no width is set on the column, only in webkit and IE 7/8 */
  height: auto;
}
p {
  margin: 0 0 1.5em;
  padding: 0;
}
em,
dfn {
  font-style: italic;
}
ins {
  border-bottom: none;
  text-decoration: none;
}
pre,
code,
tt,
samp,
kbd,
var {
  font-size: 1em;
  font-family: Consolas, "Lucida Console", Menlo, Monaco, "DejaVu Sans Mono", monospace, sans-serif; /* sans-serif Safari hack */
}
blockquote,
q {
  font-style: italic;
  quotes: "" "";
}
blockquote {
  margin: 0 0 1.5em;
  padding: 0 0 0 3em;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}
blockquote p {
  margin: 0;
}
strong,
dfn,
caption,
th {
  font-weight: 700;
}

/* Lists */
ul,
ol {
  margin: 0 0 1.5em 40px;
  padding: 0;
}
.item-list ul,
.item-list ol {
  margin: 0 0 0 15px;
  padding: 0;
}
.item-list ul li {
  margin: 0;
  padding: 0;
}
ul ul,
ul ol,
ol ol,
ol ul,
.block ul ul,
.block ul ol,
.block ol ol,
.block ol ul,
.item-list ul ul,
.item-list ul ol,
.item-list ol ol,
.item-list ol ul {
  margin-bottom: 0;
}
ul {
  list-style-type: disc;
}
ul ul ul {
  list-style-type: square;
}
ol {
  list-style-type: decimal;
}
ol ol {
  list-style-type: lower-alpha;
}
ol ol ol {
  list-style-type: lower-roman;
}
ul ul,
ul ul ul ul {
  list-style-type: circle;
}
dt {
  font-weight: 700;
}
dd {
  margin: 0 0 1.5em 40px;
}

li {
  margin-bottom: 0.75em;
}
