/* html elements */
/* medium blue */
/* dark blue */
/* deep blue */
/* new light blue */
/* yellow gold */
/* Reading Rockets yellow */
/* red */
/* dark red */
/**
 * The default font size is 16px x 81.3% = 13px
 * Use http://pxtoem.com to calculate proper font size and scale.
 *
 * The font size is set on the html element so we can dynamically
 * resize the font using body classes.
 */
html {
  font-size: 100%;
}

body {
  font-family: "open-sans", Arial, Helvetica, sans-serif;
  line-height: 1.6;
  font-size: 1.0625rem;
  color: #191919;
}

h1 {
  font-size: 1.875rem;
  line-height: 1.25;
  margin-bottom: 23.9976px;
  margin-top: 10px;
  font-weight: 700;
}

@media (min-width: 48em) {
  h1 {
    font-size: 3rem;
    margin-top: 20px;
  }
}

h2 {
  font-size: 1.563rem;
  line-height: 1.25;
  margin-bottom: 8px;
  margin-top: 25px;
  font-weight: 700;
  padding-bottom: 20px;
}

@media (min-width: 48em) {
  h2 {
    font-size: 1.55rem;
  }
}

h3 {
  font-size: 1.359rem;
  line-height: 1.4;
  margin-bottom: 6.666px;
  margin-top: 10px;
  font-weight: 700;
  margin-bottom: 6px;
  margin-top: 3px;
  clear: both;
  font-family: "open-sans", Arial, Helvetica, sans-serif;
}

@media (min-width: 48em) {
  h3 {
    font-size: 1.2rem;
    margin-top: 20px;
  }
}

h4 {
  font-size: 1.077em;
}

h1,
h2,
h4 {
  font-family: multi-display, sans-serif;
}

h5,
h6 {
  font-size: 1em;
}

a img {
  border: none;
}

img {
  max-width: 100%;
  /* flexible images - can cause issues in table cells where no width is set on the column, only in webkit and IE 7/8 */
  height: auto;
}

p {
  margin: 0 0 1.5em;
  padding: 0;
}

em,
dfn {
  font-style: italic;
}

ins {
  border-bottom: none;
  text-decoration: none;
}

pre,
code,
tt,
samp,
kbd,
var {
  font-size: 1em;
  font-family: Consolas, "Lucida Console", Menlo, Monaco, "DejaVu Sans Mono", monospace, sans-serif;
  /* sans-serif Safari hack */
}

blockquote,
q {
  font-style: italic;
  quotes: "" "";
}

blockquote {
  margin: 0 0 1.5em;
  padding: 0 0 0 3em;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

blockquote p {
  margin: 0;
}

strong,
dfn,
caption,
th {
  font-weight: 700;
}

/* Lists */
ul,
ol {
  margin: 0 0 1.5em 40px;
  padding: 0;
}

.item-list ul,
.item-list ol {
  margin: 0 0 0 15px;
  padding: 0;
}

.item-list ul li {
  margin: 0;
  padding: 0;
}

ul ul,
ul ol,
ol ol,
ol ul,
.block ul ul,
.block ul ol,
.block ol ol,
.block ol ul,
.item-list ul ul,
.item-list ul ol,
.item-list ol ol,
.item-list ol ul {
  margin-bottom: 0;
}

ul {
  list-style-type: disc;
}

ul ul ul {
  list-style-type: square;
}

ol {
  list-style-type: decimal;
}

ol ol {
  list-style-type: lower-alpha;
}

ol ol ol {
  list-style-type: lower-roman;
}

ul ul,
ul ul ul ul {
  list-style-type: circle;
}

dt {
  font-weight: 700;
}

dd {
  margin: 0 0 1.5em 40px;
}

li {
  margin-bottom: 0.75em;
}
